body {
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    overflow: hidden;
}

#view-default-view {
    width: 100%;
    height: 100%;
}

mapboxgl-canvas {
    left: 0;
    position: absolute;
}

a {
    color: white;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#about-page {
    margin-bottom: 5%;
    height: calc(100vh - 0.1px);
    overflow-y: scroll;
}

.main-map {
    z-index: -100;
    position: relative;
    height: 100%;
}

.sidelegend {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
}

.generate-menu {
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: 1000;
}

.table-button {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.loading {
    z-index: 1000 !important;
}

.settings-menu {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1000;
}

/* map tooltip */

.map-tooltip {
    background-color: rgba(245, 0, 87, .15);
    backdrop-filter: blur(15px);
    border-radius: 10px;
    padding: 20px;
}

.map-tooltip>b {
    color: white;
    font-size: small;
}

.mrms-tooltip {
    margin-left: 4px;
    width: .7em;
}

/* overriding so the backdrop blurs and looks cool */

.MuiDialog-container {
    backdrop-filter: blur(10px);
}

.MuiBackdrop-root {
    backdrop-filter: blur(5px);
}

/* notification animation for generate */

#generate-button.MuiButtonBase-root {
    border: 2px solid red;
    animation: mymove 2s infinite;
}

@keyframes mymove {
    0% {
        border-color: #f50057;
    }
    50% {
        border-color: white;
    }
    100% {
        border-color: #f50057;
    }
}